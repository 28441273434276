import "jquery-smooth-scroll";
import "jquery.easing";
import { isMobile } from "./isMobile";

/*
 *
 * ----------------------------------------------- */
jQuery(function ($) {
  $(".page-top").on("click", function (event) {
    $.smoothScroll({
      easing: "swing",
      speed: 400,
    });

    return false;
  });
});

/*
 *
 * ----------------------------------------------- */
jQuery(window).on("load scroll", function () {
  var $pageTop = $(".page-top");
  if ($(this).scrollTop() > 10) {
    $pageTop.fadeIn();
  } else {
    $pageTop.fadeOut();
  }

  if ($(this).scrollTop() + $(window).height() > $("footer").offset().top) {
    $pageTop.addClass("fixed");
  } else {
    $pageTop.removeClass("fixed");
  }

  if (isMobile()) {
    if (
      $(this).scrollTop() + $(window).height() >
      $("footer").offset().top - 130
    ) {
      $pageTop.addClass("fixed");
    } else {
      $pageTop.removeClass("fixed");
    }
  }
});
