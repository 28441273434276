import { isMobile } from "./isMobile";

/*
 * Fixed position header
 * ----------------------------------------------- */
jQuery(window).on("load", function () {
  if (isMobile()) {
    var $header = $(".header");
    var $hamburger = $(".hamburger");
    $(".header-menu").hide();

    function disableScroll() {
      // To get the scroll position of current webpage
      var TopScroll = window.pageYOffset || document.documentElement.scrollTop;
      var LeftScroll =
        window.pageXOffset || document.documentElement.scrollLeft;
      // if scroll happens, set it to the previous value
      window.onscroll = function () {
        window.scrollTo(LeftScroll, TopScroll);
      };
    }

    function enableScroll() {
      window.onscroll = function () {};
    }

    $hamburger.on("click", function () {
      $(this).toggleClass("hamburger-active");
      $header.toggleClass("header-open");
      $(".header-menu").toggleClass("open").slideToggle();

      if ($(this).hasClass("hamburger-active")) {
        disableScroll();
      } else {
        enableScroll();
      }
    });
  }

  if ($(".loader").length) {
    setTimeout(function () {
      $(".loader").addClass("loaded");
    }, 3500);
  }

  if ($(".loader-child").length) {
    setTimeout(function () {
      $(".loader-child").addClass("loaded");
    }, 500);
  }
});
