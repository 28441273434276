// import "./polyfill/number-is-nan";
import "./modules/swiper";
import "./modules/aos";

// You can specify which plugins you need

import "./modules/webfont";
// import "./modules/view-more";
import "./modules/page-top";
// import "./modules/toggle-disabled-input-field";
// import "./modules/jqueryvalidation";
// import "./modules/menu-trigger";
// import "./modules/bs.tab";
import "./modules/js-header";
import "./modules/js-footer";
// import "./modules/waypoints";
// import "./modules/dropdown-hover";
import "./modules/smoothScroll";
import "./modules/js-tab";

import "./modules/jquery-match-height";
// import "./modules/luxy";
// import "./modules/banner-fixed-bottom";
