/*
 * Fixed position footer
 * ----------------------------------------------- */
jQuery(window).on("load", function () {
  if ($(window).width() >= 768) {
    $(".list-tab-contact li").click(function () {
      $(".list-tab-contact li a").removeClass("rounded");
      $(".list-tab-contact li").removeClass("active");
      $(this).addClass("active");
      $(".tab-content").hide();
      var activeTab = $(this).find("a").attr("href");
      $(activeTab).fadeIn();
      return false;
    });

    $(".list-tab-contact li a").removeClass("rounded");
    var linkidtabs = $(this).attr("href");
    $(location).attr("href", linkidtabs);
    var locadafters = window.location.hash;
    $('.list-tab-contact li[data-tab="' + locadafters + '"]').click();
    if (locadafters === "#tab2") {
      $(".list-tab-contact li a").removeClass("rounded rounded-2 rounded-3");
      $(".list-tab-contact li:nth-child(1)").find("a").addClass("rounded");
    } else if (locadafters === "#tab3") {
      $(".list-tab-contact li a").removeClass("rounded rounded-2 rounded-3");
      $(".list-tab-contact li:nth-child(1)").find("a").addClass("rounded-3");
      $(".list-tab-contact li:nth-child(2)").find("a").addClass("rounded-2");
    }

    $(".ft-child-menu.tab li a").click(function () {
      var linkidtab = $(this).attr("href");
      $(location).attr("href", linkidtab);
      var locadafter = window.location.hash;
      $('.list-tab-contact li[data-tab="' + locadafter + '"]').click();
    });

    $(".list-tab-contact li:nth-child(1)").click(function () {
      $(".list-tab-contact li a").removeClass("rounded rounded-2 rounded-3");
    });

    $(".list-tab-contact li:nth-child(2)").click(function () {
      $(".list-tab-contact li a").removeClass("rounded rounded-2 rounded-3");
      $(".list-tab-contact li:nth-child(1)").find("a").addClass("rounded");
    });

    $(".list-tab-contact li:last-child").click(function () {
      $(".list-tab-contact li a").removeClass("rounded rounded-2 rounded-3");
      $(".list-tab-contact li:nth-child(1)").find("a").addClass("rounded-3");
      $(".list-tab-contact li:nth-child(2)").find("a").addClass("rounded-2");
    });
  }

  $(".tab-content .tab-label").click(function () {
    $(this).parent().toggleClass("actived");
    $(this).toggleClass("rotate");
    $(this).parent().find(".tab-content-box").slideToggle();
  });
});
